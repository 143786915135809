@import "../index.scss";

/* upcoming */
.upcoming {
  height: 100vh;

  .upcomingHeader {
    text-align: center;
    font-size: 3.5rem;
    color: var(--primary-color);
    margin-top: 5rem;

    @include respond(phone){
      font-size: 2.5rem;
      margin-bottom: -7rem;
    }

    &::after {
      content: "";
      display: block;
      width: 7rem;
      margin: 1rem auto;
      height: 0.3rem;
      background: var(--primary-color);
    }
  }

  .upcomingContainer {
    width: 100%;
    margin: auto;
    height: 100vh;
    padding: 5rem;
    display: flex;
    align-items: center;

    @include respond(phone) {
      padding: 2rem;
      position: relative;
    }

    .upcomingContainerImg {
      width: 60%;
      height: 100%;
      border-radius: 3rem;

      @include respond(phone) {
        width: 100%;
        height: 70%;
      }
    }

    .upcomingContainerDetails {
      width: 40%;
      text-align: center;
      background-color: var(--white);
      border-radius: 3rem;
      transform: translateX(5rem) translateY(13rem);
      word-break:keep-all;
      box-shadow: 0rem 0.44rem 1.81rem 0rem rgba(100, 100, 111, 0.2);

      @include respond(phone) {
        transform: translateX(-7rem) translateY(5rem);
        position: absolute;
        top: 2rem;
        right: -6rem;
      }

      & h1 {
        font-size: 3rem;
        color: var(--primary-color);
        margin: 5rem 3rem 2rem 3rem;

        @include respond(phone) {
          font-size: 2rem;
        }
      }

      & p {
        font-size: 1.7rem;
        color: var(--primary-color);
        margin: 5rem 3rem 2rem 3rem;

        @include respond(phone) {
          font-size: 1.5rem;
        }
      }
    }
  }
}
